.product_item {
    padding: 10px;
    background-color: rgb(255, 255, 255);
    flex-basis: 20%;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.product_list_item {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height: 250px;
}


@media (max-width: 600px) {
    .product_item {
        padding: 6px;
        margin: 8px;
        width: 100% !important;
        flex-basis: 45%;
    }

    .product_list_item {
        height: 250px;
    }
}

.product_item .product_img {
    width: 100%;
    height: 150px;
    display: block;
    margin: auto;
    object-fit: fill;
}

/* @media (max-width: 600px) {
    .product_img {
        height: 129px !important;
      
    }
} */

.product_description {
    margin: 10px 0px;
    color: black;
}

.rating_div i {
    color: #FFB800;
    margin-right: 10px;
}

.rating_div span {
    margin-top: -10px;
}

.price {
    color: #F14306;
    margin-top: 10px;
    font-size: 16px;
}