/* styles.css */

/* Centered heading */
h1 {
    text-align: center;
  }
  
  /* Styling for the "Create Subscription Plan" button */
  .btn-right {
    float: right;
  }
  
  /* Styling for form container */
  .form-container {
    max-width: 400px;
    margin: 0 auto;
  }
  
  /* Styling for form inputs */
  .form-control {
    margin-bottom: 15px;
  }
  
  /* Styling for error messages */
  .error {
    color: #dc3545;
    font-size: 12px;
  }
  
  /* Styling for success and danger buttons */
  .btn-success {
    background-color: #28a745;
    color: #fff;
  }
  
  .btn-danger {
    background-color: #dc3545;
    color: #fff;
  }
  
  /* Styling for table */
  .table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: #fff;
  }
  
  /* Centered heading for modals */
  .h2-center {
    text-align: center;
  }
  
  /* Styling for modal content */
  .modal-content {
    border-radius: 0;
  }
  
  /* Styling for modal form */
  .modal-form {
    max-width: 400px;
    margin: 0 auto;
  }
  
  /* Styling for modal form inputs */
  .modal-form .form-control {
    margin-bottom: 15px;
  }
  
  /* Styling for modal buttons */
  .modal-buttons {
    text-align: right;
    margin-top: 15px;
  }
  
  /* Styling for modal save button */
  .btn-save {
    background-color: #007bff;
    color: #fff;
  }
  
  /* Styling for modal cancel button */
  .btn-cancel {
    background-color: #dc3545;
    color: #fff;
  }
  