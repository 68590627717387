.rating_div {
    display: flex;
    margin: 10px 0px;
}

.rating_div i {
    color: #FFB800;
}

.vendortoppanel{
    box-shadow: 0px 0px 10px rgb(161, 160, 160);
    padding: 10px;
    border-radius: 10px 15px;
}
.bbbox{
    box-shadow: 0px 0px 10px rgb(161, 160, 160);
    padding: 5px;
    border-radius: 10px 15px;
    margin-top: 10px;
}

.x_ {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #7692B4;

}

.title_section h3 {
    color: #F14306;
}

.editing_section {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005em;
    color: #8D8D8D;
}

.vendor_name {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
}

.contact_list {
    line-height: 1.5;
}

.list_buttons button {
    padding: 10px;
    background-color: white;
    color: black;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 100px;
    line-height: 1.4;
    border-radius: 10px;
    box-shadow: 7px 8px 10px #b6b6b6;
}
.list_buttons button:hover{
    background-color: white;
    color: black;
}

.create_product_section button {
    padding: 20px;
    color: white;
    background-color: #F14306;
}

.footer_section {
    line-height: 1.6;
}

.vssa{
    background-color: white;
    padding: 10px;
    box-shadow: 0px 0px 10px gray;
    width: 100%;
    margin-top: 20px;
    border-radius: 10px;
    padding: 10px 20px;
}
.vssa .card{
    padding: 10px; 
}
  
  .vssacollapsiblebutton {
    cursor: pointer;
    background-color: orangered;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease; 
  }
  
  .vssacollapsiblebutton:hover {
    background-color: rgb(255, 38, 0);
    box-shadow: 0px 0px 10px gray;
  }

@media screen and (max-width: 900px) {
    .vssa {
        max-height: 1200px;
        overflow: hidden;
        transition: max-height 0.3s ease; 
    }
  
    .vssa.collapsed {
        max-height: 0px; 
        padding: 0px;
    }
}

@media screen and (min-width: 900px) {
    .vssacollapsiblebutton {
        display: none;
    }
}