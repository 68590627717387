.back_button_container button {
    background-color: black;
    padding: 5px 10px;
    gap: 5px;

    width: 101px;
    height: 38px;

    /* ON BACKGROUND */

    background: #0D0F11;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 20px;
}

.back_button_container button span {
    color: white;
}

.back_button_container button i {
    color: white;
}

.order_detail {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #0D0F11;
}

.order_number {
    font-weight: 700;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.0025em;
    color: #F14306;
}

.cart_summary_container {
    width: 70%;
}

@media (max-width: 600px) {
    .cart_summary_container {
        width: 100%;
    }
}

.summary_title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    text-align: center;
    margin-bottom: 10px;
}

.footer_container p big {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: right;
    color: #F14306;
}

.footer_container p b {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #0D0F11;
    margin-right: 1rem;
}

.footer_container p {
    margin-bottom: 10px;
}

.action_button button {
    padding: 20px;
    width: 200px;
    background: #F14306;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    margin-right: 10px;
    color: white;
}

@media (max-width: 600px) {
    .action_button button {
        padding: 10px 20px;
        width: 160px;
    }

}

.location_container {
    width: 460px;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 0px 10px;
    line-height: 2;
}

.location_container select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
}

.pickup_date {
    display: flex;
    justify-content: space-between;
    width: 460px;
    background: #FFFFFF;
    border-radius: 5px;
    padding:  10px;
    line-height: 2;
}

.pickup_date input {
    border: none;
    outline: none;
}