
.withdraw {
    text-align: center;
    margin-top: 20px;
  }
  
  .wallet-details {
    margin-bottom: 20px;
  }
  
  input {
    padding: 5px;
    margin-right: 10px;
    width: 100px;
  }
  
  .confirm-button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 10px;
  }
  .confirm-button:hover{
    background-color: #0359b4;
    transform: scale(1.1);
  }
  
  .modal2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }
  
  .modal-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
  }
  

.wallet-details-container{
  background-color: white;
  box-shadow: 0px 0px 10px rgb(201, 199, 199);
  padding: 10px;
  border-radius: 20px;
  margin-top: 10px;
  text-align: center;
}

.wallet-details{
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 4%;
}

.wallet-details>div{
  box-shadow: 0px 0px 10px rgb(201, 199, 199);
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .wallet-details{
    display: block;
    width: 94%;
    margin: auto;
  }

  .wallet-details>div{
    margin-bottom: 20px;
  }
}

.withdraw-section{
  background-color: white;
  box-shadow: 0px 0px 10px gray;
  border-radius: 20px;
  padding: 20px;
}