.container {
  position: fixed;
  top: -10%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 15px 10px;
  background-color: white;
  box-shadow: 4px 9px 10px #cacaca;
  border-left: 5px solid green;
  z-index: 9001;
  cursor: pointer;
  animation: fade_out 0.5s ease-in forwards;
}

@keyframes fade_out {
  from {
    top: -50%;
  }
  to {
    top: 20%;
  }
}

@media (max-width: 600px) {
  .container {
    width: 80%;
  }
}

.container p {
  color: black;
  font-size: 15px;
}
