.product_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 600px) {
    .product_container {
        flex-direction: column;
    }
}

.product_image_ {
    flex-basis: 48%;
}

@media (max-width: 600px) {
    .product_image_ {
        width: 100%;
        margin-bottom: 10px;
        border-radius: unset
    }
}

.product_image_ img {
    width: 100%;
    border-radius: 20px;
}

@media (max-width: 600px) {
    .product_image_ img {
        border-radius: 0px;
    }
}