.title {
    color: #F14306;
}

.title i {
    margin-right: 10px;
}

.aside_nav_wrapper_list {
    margin-top: 20px;
}

.aside_nav_wrapper_list p {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0015em;

    color: #8D8D8D;

}

.aside_nav_wrapper_list ul li {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    line-height: 1.6;
    font-size: 15px;
    color: #0D0F11 !important;

}

.aside_nav_wrapper_list ul li  a {
    color: black;
}