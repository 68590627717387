
.cart_main {
    display: flex;
}

@media (max-width: 600px) {
    .cart_main {
        flex-direction: column;
    }
}



.first_col {
    flex-basis: 70%;
}

.summarry_section {
    flex-basis: 30%;
    margin-left: 10px;
}

@media (max-width: 600px) {
    .summarry_section {
        margin-left: unset;
    }
}
.summary_wrapper {
    background-color: white;
    /* margin: 0px 10px; */
    padding: 10px;
}

@media (max-width: 600px) {
    .summary_wrapper {
        margin-top: 1rem;
    }
}

.sub_total_section {
    display: flex;
    justify-content: space-between;
    line-height: 1.6;
}

.cart_navs_container button {
    padding: 10px;
    background-color: #fff;
    margin-right: 10px;
}

.cart_hed_cetails_line {
    width: 100%;
    background-color: white;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cart_hed_cetails_line p {
    color: #F14306;
    cursor: pointer;
}

.check_out_btn button {
    width: 100%;
    padding: 15px;
    background-color: #F14306;
    color: white;
    font-size: 20px;
}

.check_out_btn i {
    color: white;
    margin-right: 10px;
}


.navigating_button button {
    padding: 17px 44px;
    background-color: #F14306;
    margin-right: 10px;
    color: white;
}

.application_form {
    margin-bottom: 15px;
}

.application_form input,
.application_form select {
    width: 50%;
    display: block;
    padding: 10px;
}

.application_form label {
    font-size: 12px;
}


.check_section {
    display: flex;
    align-items: center;

}

.terms_section {
    display: flex;
    align-items: center;
}

.terms_section input {
    margin-right: 10px;
}

.terms_section p {
    width: 30%;
}

.submit_buttin button {
    padding: 10px 30px;
    width: 40%;
    background-color: #F14306;
    display: block;
}

.empty_cart_image {
    width: 300px;
    display: block;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgb(186, 186, 186);
}

.other_cart_link{
    background-color: white;
    margin-top: 20px;
    padding: 10px;
}