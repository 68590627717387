.payment_type_container {
    background-color: white;
    padding: 10px;
}

.payment_type_container input[type=radio] {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    color: #F14306;
    background-color: #F14306;
}

.flex_div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.wallet_section {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.wallet_section * {
    margin: 0px 10px;
    color: #F14306;
}


/* payment method */
.payment_method_container {
    background-color: white;
    width: 100%;
    padding: 10px;
}


@media (max-width: 600px) {
    .payment_method_container {
        width: 100%;
    }
}

.method_list_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.4;
    margin-bottom: 10px;
}

.price_div h3 {
    color: #F14306;
}

.floating_buton {
    display: flex;
    align-items: center;
}

.floating_buton button {
    padding: 10px;
    background-color: #7692B4;
    margin: 10px 20px;
    border-radius: 6px;
    color: white;
}

@media (max-width: 600px) {
    .floating_buton button {
        margin: 1px 6px;
    }

}

.percentage_number {
    padding: 10px;
    background: unset;
    border: 1px solid gray;
    outline: none;
}