.input_wrapper input,
.input_wrapper select {
    padding: 10px;
    gap: 10px;
    width: 294px;
    height: 56px;
    background: #FFFFFF;
    border: 1px solid #0A2342;
    border-radius: 5px;
    display: block;
    margin: 10px 0px;
}

@media (max-width: 600px) {

    .input_wrapper input,
    .input_wrapper select {
        width: 100%;
    }
}

.button_container button {
    padding: 20px 24px;
    color: white;
    background-color: #F14306;
}



.input_wrapper p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #0D0F11;
}

