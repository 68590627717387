.main {
    max-width: 80%;
    display: flex;
    margin: auto;
    min-height: 100vh;
}

.aside {
    flex-basis: 25%;
    /* background-color: white; */
    margin-top: 20px;
    margin: 10px;
    /* padding: 10px; */
}

.article {
    flex-basis: 75%;
    /* background-color: white; */
    padding: 10px;
    margin-top: 20px;
    margin: 10px;
}

@media (max-width: 600px) {
    .main {
        max-width: 100% !important;
        flex-direction: column;
    }

    .article {
        width: 100%;
        margin: unset;
    }
}