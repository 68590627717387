.category_nav_container {
}

.check_box_list {
    display: flex;
    align-items: flex-end;
}

.check_box_list input {
    width: 20px;
    height: 20px;
    margin-right: 20px;
    margin-top: 10px;
    background-color: #F14306;
    color: #F14306;
}

.price_rage_container {
    display: flex;
    align-items: center;
}

.price_rage_container input {
    padding: 10px;
    border: 1px solid black;
    width: 50%;
    margin-left: 10px;
    margin-top: 10px;
    /* border-radius: 10px; */
    border: none;
    outline: none;
}

.price_rage_container p {
    font-weight: bolder;
    width: 50px;
}

.title {
    margin-top: 10px;
}

.check_box_list i {
    margin-right: 10px;
}

.check_box_list i.color {
    color: #F14306;
}

.apply_btn button {
    padding: 10px;
    color: white;
    background-color: #F14306;
    width: 80%;
    margin: auto;
    border-radius: 5px;
    margin-bottom: 12px;
}

.filter_btn button {
    padding: 10px;
    color: white;
    background-color: #F14306;
    width: 80%;
    margin: auto;
    border-radius: 5px;
    margin-bottom: 12px;
    /* hide on desktop */
    display: none;
}

.reset button {
    padding: 10px;
    width: 80%;
    margin: auto;
    border-radius: 5px;
}

@media(max-width:760px){
    .filter_btn button{
        display: block;
    }

    .filter_section{
        display: none;
    }
    
}

