.vsa{
    background-color: white;
    padding: 10px;
    box-shadow: 0px 0px 10px gray;
    width: 100%;
    margin-top: 20px;
    border-radius: 10px;
}
.vsa .card{
    padding: 10px; 
}
  
  .collapsible-button {
    cursor: pointer;
    background-color: orangered;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease; 
    margin-top: 20px;
  }
  
  .collapsible-button:hover {
    background-color: rgb(255, 38, 0);
    box-shadow: 0px 0px 10px gray;
  }

@media screen and (max-width: 767px) {
    .vsa {
        max-height: 500px;
        overflow: hidden;
        transition: max-height 0.3s ease; 
    }
  
    .vsa.collapsed {
        max-height: 0px; 
        padding: 0px;
    }
}

@media screen and (min-width: 767px) {
    .collapsible-button {
        display: none;
    }
}
