.vendor_head_name p:first-child {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #0D0F11;
}

.vendor_head_name p:last-child {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #7692B4;

}

.contact_detail {
    margin-top: 10px;
}

.contact_detail p {
    font-weight: 400;
    font-size: 15px;
    color: #0D0F11;
}

.contact_detail p:first-child {
    font-style: normal;
    font-weight: 400;
    /* font-size: 20px; */
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #0D0F11;

}

.contact_detail p:last-child {
    font-style: normal;
    font-weight: 400;
    /* font-size: 24px; */
    line-height: 28px;
    color: #0D0F11;

}

.other_detail_section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

@media (max-width: 600px) {
    .other_detail_section .other_detail_list {
        width: 100%;
        margin-bottom: 10px;
    }
}

.other_detail_list {
    background-color: white;
    padding: 7px 20px;
    margin-right: 10px;
}

.other_detail_list p:first-child {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #0D0F11;
}

.other_detail_list p:last-child {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
}

.activate_button button {
    padding: 10px 20px;
    margin-right: 10px;
    margin-top: 20px;
    color: white;
}

.activate_button button:first-child {
    background-color: #F14306;
}

.activate_button button:last-child {
    background-color: red;
}