/* .product_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: white;
} */

/* @media (max-width: 600px) {
    .product_container {
        align-items: center;
        justify-content: center;
        
    }   
}  */

.product_container {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-right: 10px;
}

@media (min-width: 600px) {
    .product_container {
        grid-template-columns: 33.3% 33.3% 33.3%;
    }
}

@media (min-width: 1000px) {
    .product_container {
        grid-template-columns: 25% 25% 25% 25%;
    }
}

@media (min-width: 1200px) {
    .product_container {
        grid-template-columns: 20% 20% 20% 20% 20%;
    }
}

@media (min-width: 1400px) {
    .product_container {
        grid-template-columns: 16.6% 16.6% 16.6% 16.6% 16.6% 16.6%;
    }
}