.container {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.5);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 100;
}

.wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: auto;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}

@media (max-width: 600px) {
    .wrapper {
        width: 100%;
    }
}

.wrapper textarea {
    width: 100%;
    height: 250px;
    /* border: 2px solid gray; */
    box-shadow: 6px 8px 10px #dbdbdb;
}

.wrapper button {
    padding: 20px 40px;
    display: block;
    margin: auto;
    margin-top: 20px;
}

.start_container {
    margin-top: 20px;
    text-align: center;
}

.start_container svg {
    font-size: 25px;
    margin-right: 20px;
    /* transition: all ease .2s; */
}

.close_container {
    background-color: unset;
    color: red;
}