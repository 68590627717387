.order_table table {
    width: 100%;
    padding: 10px;
    background-color: #fff;
    border-collapse: collapse;
}

@media (max-width: 600px) {
    .order_table table { 
        width: 100%;
    }
}

.header_section td {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.001em;
    color: #8D8D8D;
    padding: 10px;
    text-align: left;
}
.lists_ {
    margin-top: 20px;
}
.lists_ td {
    font-style: normal;
    font-weight: 400;
    /* font-size: 20px; */
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #0D0F11;
    padding: 0px 10px ;
    text-align: left;
}

.search_order_input {
    position: relative;
    display: inline-block;
    margin-top: 20px;
    margin-bottom : 10px;
}

.search_order_input input {
    width: 250px;
    padding: 10px;
    border: 0px;
    outline: none;
    background-color: white;
    height: 40px;
    border-radius: 5px;
}

.search_icon {
    width: 40px;
    height: 40px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    right: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.search_icon i {
    color: white;
}

