.title {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #0D0F11;
}

.brand_container_first {
    margin-bottom: 20px;
}

.brand_container_first select,
.brand_container_first input {
    width: 430px;
    height: 56px;
    left: 0px;
    top: 33px;
    background: #FFFFFF;
    border: 1px solid #0A2342;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 10px;
}

@media (max-width: 600px) {

    .brand_container_first select,
    .brand_container_first input {
        width: 100%;
    }
}
.button_container {
    display: flex;
    align-items: center;
}
.button_container button {
    width: 169px;
    height: 56px;
    background: #F14306;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    color: white;
    margin-right: 20px;
}

.button_container {
    display: flex;
}

