.input_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 39%;
}
@media (max-width: 600px) {
    .input_wrapper {
        width: 100%;
    }
}

.input_wrapper input {
    padding: 8px;
    gap: 8px;

    width: 353px;
    height: 32px;

    background: #FFFFFF;
    border: 1px solid rgba(151, 150, 153, 0.24);
    border-radius: 2px;
    margin-left: 10px;
}

.input_wrapper textarea {
    padding: 8px;
    gap: 8px;

    width: 100%;
    height: 136px;

    background: #FFFFFF;
    border: 1px solid rgba(151, 150, 153, 0.24);
    border-radius: 2px;
}

.file_upload i {
    font-size: 40px;
}




.action_button {
    display: flex;
    align-items: center;
}

.action_button button {
    padding: 10px;
    color: white;
    background-color: #F14306;
    margin-right: 10px;
}

.footer_actions {
    display: flex;
    align-items: center;
}

.footer_actions input[type=checkbox] {
    padding: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.footer_actions * {
    margin-right: 10px;
}

.footer_actions input[type=date],
.footer_actions input[type=time] {
    padding: 6px;
    background-color: white;
    border: none;
    outline: none;
}