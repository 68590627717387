.wrapper_list h1 {
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    /* identical to box height */

    letter-spacing: 0.0025em;

    color: #000000;
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */

}

.flex_para_div {
    display: flex;
}

.flex_rows {
    display: flex;
    align-items: baseline;
}

.flex_rows i {
    font-size: 20px;
    margin-right: 10px
}

.flex_rows p {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    margin-right: 10px;
    color: #000000;
}

.question {
    margin-bottom: 7px;
}

.qustionInput,
.seelct_container select {
    padding: 10px;
    width: 300px;
    border-radius: 10px;
    outline: none;
    border: none;
}

.answer_container textarea {
    display: block;
    margin-top: 20px;
    padding: 10px;
    width: 300px;
    padding: 10px;
    border-radius: 10px;
    border: none;
    outline: none
}


.create_button {
    padding: 10px;
    background-color: #F14306;
    border-radius: 10px;
    color: white;
}

.control_icon {
    margin-left: 10px;
}

.control_icon i {
    margin-right: 10px;
}

.editable_section_para:focus {
    border: 3px solid gray;
}