.header {
    position: sticky;
    top: 0;
    width: 100%;
    padding: 10px;
    display: flex;
    /* align-items: center; */
    justify-content: space-around;
    background-color: #0D0F11;
    color: white;
    z-index: 9000;
}



.logo_container {
    flex-basis: 5%;
}

@media (max-width: 600px) {
    .logo_container {
        display: none;
    }
}


.search_container {
    flex-basis: 40%;
    position: relative;
}



.search_container input {
    width: 100%;
    padding: 10px;
    border-radius: 7px;
    border: none;
    outline: none;
}

@media (max-width: 600px) {
    .search_container {
        flex-basis: 90%;
        position: relative;
        margin-right: 20px;
    }


    .search_container input {
        width: 80%;
        /* width: 80% !important; */
    }
}

.search_container input::placeholder {
    color: black
}

.custome_search_button {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 40px;
    height: 35px;
    background-color: #F14306;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
}

@media (max-width: 600px) {
    .custome_search_button {
        height: 38px !important
    }
}

.custome_search_button i {
    font-size: 20px;
    color: white;
}


.third_column {
    flex-basis: 20%;
    display: flex;

}

@media (max-width: 600px) {
    .third_column {
        align-items: flex-end;
        justify-content: flex-end;
        /* margin-right: -27px; */
    }
}

.account_div {
    display: flex;
    align-items: center;
}

.account_div i {
    font-size: 26px;
    margin-right: 5px;
}

.account_div {
    margin-right: 20px;
}

.account_div i {
    color: white;
}

.logo_container img {
    width: 200px;
}

@media (max-width: 600px) {
    .logo_container img {
        margin: auto;
        display: block;
    }

  
}

.account_div a {
    color: white;
}

.account_div i {
    color: white;
}

.custome_search_button {
    cursor: pointer;
}

@media (max-width: 600px) {
    .hide_on_mobile {
        display: none;
    }

    .cart_length {
        width: 23px;
    }
}