.aside_container {
    flex-basis: 20%;
    width: 100vh;
}


@media (max-width: 600px) {
    .aside_container {
        display: none;
    }


}

.aside_nav {
    text-align: center;
}

.aside_nav button {
    margin: 10px;
    margin-top: 20px;
    padding-bottom: 5px;
    background-color: unset;
 
}

.brand_input {
    width: 80%;
    display: block;
    margin: auto;
    position: relative;
}

.brand_input input {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: none;
    outline: none;
    background-color: #ddd;
}

.brand_input input::placeholder {
    color: black;
}

.search_icon_container {
    position: absolute;
    top: 10px;
    right: 10px;
}

.search_icon_container i {
    color: #F14306;
}

.bran_items_container {
    width: 80%;
    margin: auto;
    display: block;
    margin-top: 10px;
}

.bran_items_container p {
    margin-bottom: 10px;
    cursor: pointer;
}


.ads_first_image1 {
    width: 100%;
    margin-top: 20px;
}

.ads_first_image2 {
    width: 100%;
    margin-top: 20px;
    border-radius: 10px;
}


.bran_items_container p {
    display: flex;
    align-items: center;
}

.bran_items_container p img {
    margin-right: 10px;
    width: auto;
    height: 20px;
}