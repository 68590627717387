.nav_container {
    margin: 20px 0px;
}

.nav_container button {
    padding: 10px;
    margin-right: 10px;
    color: #7692B4;
    background-color: unset;
    transition: all ease .5s;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.slide_container {
    display: flex;
    align-items: baseline;
    margin-top: 14px;
    margin-bottom: 10px;
}

.slide_container div {
    margin-right: 10px;
}

.slide_container div img {
    border-radius: 10px;
}

.live_controller {
    display: flex;
    align-items: center;
    margin-right: 20px;
}


.live_controller div {
    margin-right: 20px;
    text-align: center;
    padding: 10px;
}



.live_controller div p,
.live_controller div i {
    color: #7692B4;
}


.available_title {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #0D0F11;
    margin: 10px 0px;
}



.save_changes_container button {
    padding: 20px;
    background: #F14306;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    color: white;
}

.second_list_ {
    margin: 14px 0px;
}