.rating_div {
    display: flex;
    margin: 10px 0px;
}

.rating_div i {
    color: #FFB800;
}

.container {
    padding: 10px 20px;
    background-color: white;
    border-radius: 10px;
}

.x_ {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #7692B4;

}

.title_section h3 {
    color: #F14306;
}

.editing_section {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005em;
    color: #8D8D8D;
}

.vendor_name {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
}

.contact_list {
    line-height: 1.5;
}

.list_buttons button {
    padding: 10px;
    background-color: white;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 100px;
    line-height: 1.4;
    border-radius: 10px;
    box-shadow: 7px 8px 10px #b6b6b6;
}

.create_product_section button {
    padding: 20px;
    color: white;
    background-color: #F14306;
}

.footer_section {
    line-height: 1.6;
}
