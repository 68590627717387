table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: #fffefe;
  border-collapse: collapse;
  border-radius: 10px;
}

th{
  text-align: left;
  background-color: rgb(190, 189, 189);
  padding: 3px;
}
td{
  border-bottom: 1px solid gray;
  padding: 5px;
}

/* technician dashboard */
.subscription-details{
  background-color: white;
  box-shadow: 0px 0px 5px gray;
  border-radius: 10px;
  padding: 10px;
}