.container {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: unset;
    border: 5px solid #F14306;
    border-top-color: white;
    animation: spinner .5s infinite linear;
    display: block;
    margin:10px auto;
}


@keyframes spinner {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}