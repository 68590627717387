/* 12 CSS CODE */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "HelveticaBold";
  src: url("../public/Helvetica/Helvetica-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica_";
  src: url("../public//Helvetica/Helvetica.ttf") format("truetype");
}

body {
  font-family: "Helvetica_", Arial, Helvetica, sans-serif;
  background-color: #e9e9e9;
}

ul li {
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #f14306;
}

i {
  cursor: pointer;
  color: #8b8488;
}

a.nav_link {
  color: black;
  display: block;
  width: 100%;
  text-align: left;
  padding: 10px;
  transition: all ease 0.5s;
  background-color: white;
}

a.nav_link:hover,
a.nav_link:focus,
a.nav_link.active {
  background-color: #7692b4;
  color: white;
}

/* Navlink for admin */
a.admin_nav_lisk {
  color: black;
}

a.admin_nav_lisk:hover,
a.admin_nav_lisk:focus,
a.admin_nav_lisk.active {
  color: #f14306;
}

img {
  object-fit: cover;
  cursor: pointer;
}

button {
  cursor: pointer;
  outline: none;
  border: none;
}

table tr {
  cursor: pointer;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.article_padding {
  padding: 10px;
}
/* 
.padding-10 {
  padding: 10px !important;
} */

/* width */
::-webkit-scrollbar {
  width: 7px;
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
}

/* Track */
::-webkit-scrollbar-track {
  background: unset;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(227, 227, 227);
  border-radius: 20px;
}

@media screen and (max-width: 600px) {
  ::-webkit-scrollbar-thumb {
    visibility: hidden;
  }
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(99, 99, 99, 0.4);
}

/* Prevent the app from zooming on mobile devices */
:root {
  touch-action: pan-x pan-y;
  height: 100%;
}

/* preventing IOS mobile from zooming */
@media screen and (max-width: 767px) {
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="password"],
  input {
    font-size: 16px !important;
    font-family: "Helvetica_", Arial, Helvetica, sans-serif;
  }
}

textarea {
  font-size: 16px;
  font-family: "Helvetica_", Arial, Helvetica, sans-serif;
  border: none;
  outline: none;
  resize: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
div {
  /* font-size: 14px; */
  word-wrap: break-word;
  word-break: break-word;
  font-family: "Helvetica_", Arial, Helvetica, sans-serif;
}

p {
  font-size: 14px;
}

.capitalize {
  text-transform: capitalize;
}

.centered {
  text-align: center;
}

.cancel-btn {
  border: 2px solid cornflowerblue;
  background-color: white;
  color: cornflowerblue;
  font-weight: bold;
  border-radius: 20px;
}

.confirm-btn {
  background-color: cornflowerblue;
  color: white;
  font-weight: bold;
  border-radius: 20px;
}

i.rotate_plus,
#rotate_plus {
  transform: rotate(314deg);
  display: inline-block;
}

.bold {
  font-weight: bolder;
  cursor: pointer !important;
}

.bolder {
  font-weight: 900 !important;
  cursor: pointer !important;
}

.smaller {
  font-weight: lighter;
  font-size: 12px;
}

/* sticky aside firstchild child div */
.stick_aside_div {
  position: sticky;
  top: 13px;
  /* background-color: pink; */
}

.white-color {
  color: #bbb !important;
}

#global_relative {
  position: relative !important;
}

video {
  border: none;
  outline: none;
  object-fit: cover;
}

.verified {
  color: cornflowerblue;
}

.flex_username__and__user__name__date {
  display: flex;
  align-items: center;
}

.flex_username__and__user__name__date * {
  margin-right: 3px;
}

.flex_username__and__user__name__date h4 {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: bold;
  color: #414141;
}

.flex_username__and__user__name__date h4:hover {
  text-decoration: underline;
  cursor: pointer;
}

.flex_username__and__user__name__date i {
  margin-bottom: -5px;
  font-size: 24px;
  color: cornflowerblue;
}

.flex_username__and__user__name__date p {
  color: rgb(85, 85, 85);
}

.urlifyHashAndLink {
  font-weight: 900;
  color: cornflowerblue;
}

.color-black {
  color: black;
  cursor: pointer;
}

/* Deleetiong animation  */
.toggle_delete {
  /* transition: all ease-in .5s; */
  height: 200px;
  overflow: hidden;
  animation: deletefade 0.4s ease forwards;
}

@keyframes deletefade {
  from {
    height: 200px;
  }

  to {
    height: 0px;
  }
}

.marginTopAndBottom {
  margin: 7px 0px;
}

.padding_px_10 {
  padding: 10px;
}

/* 
.your-app {
  .rsis-container {
    // do something
  }
} */

/* .your-app { */
.rsis-image {
  background-size: cover !important;
  width: 100% !important;
}

.slider_container {
  width: 100%;
  background-color: black;
  position: relative;
}

.error {
  color: red !important;
  font-weight: bold;
  margin: 10px 0px;
}

.error_message {
  color: red;
  font-weight: bold;
}

.success_message {
  color: green;
  font-weight: bold;
}

.file_loader {
  height: 10px;
  background-color: #f14306;
  position: absolute;
  bottom: 0px;
  left: 0px;
  transition: all ease 0.5s;
}

.center-pagination {
  display: block;
  width: 280px;
  margin: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* background-color: pink; */
}

.success-message-form-dorm {
  background-color: #00800080;
  padding: 10px;
  color: white;
  border: 2px solid green;
}

.btn {
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  background: #e0e0e0;
  color: #2b2b2c;
}

.btn:disabled {
  background: #e0e0e0;
  color: #2b2b2c;
  cursor: not-allowed;
}

.btn-primary {
  background: #f14306;
  color: #fff;
}

.btn-danger {
  background: #f44336;
  color: #fff;
}

/* TabComponent.css */
.tab-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.tab-header {
  display: flex;
}

.tab-item {
  padding: 8px 16px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.3s ease;
}

.tab-item:hover {
  border-bottom-color: #ccc;
}

.tab-item.active {
  border-bottom-color: #000;
}

.tab-content {
  padding: 16px;
}

.tab-pane {
  display: none;
}

.tab-pane.active {
  display: block;
}

.support-ticket {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.support-ticket-header {
  margin-bottom: 0.5rem;
}

.support-ticket-header h3 {
  font-size: 1.2rem;
  margin-bottom: 0.25rem;
}

.support-ticket-id {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
}

.support-ticket-id > .id,
.support-ticket-id > .status {
  color: #777;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  background-color: #fff;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
}

.support-ticket-id > .status {
  color: #ffe6dd;

  background-color: #f15217;
}

.support-ticket-id > .id::before {
  content: "# ";
}

.sender-email {
  color: #777;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.support-ticket-content {
  margin-bottom: 1rem;
}

.support-ticket-content p {
  margin: 0;
}

.support-ticket-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}

.support-ticket-footer p {
  margin: 0;
  font-size: 0.9rem;
  color: #777;
}

.support-ticket-actions {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
}

.btn-reply,
.btn-reply-email {
  background-color: #f14306;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-reply:hover,
.btn-reply-email:hover {
  background-color: #f14306;
}

.form-control {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.form-control label {
  margin-right: 0.5rem;
  font-size: 0.9rem;
  color: #777;
}

.status-select {
  padding: 0.3rem 0.5rem;
  font-size: 0.9rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.message-reply-form {
  margin-top: 10px;
}

.message-input {
  width: 100%;
  height: 100px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  resize: none;
}

.btn-send {
  font-size: 14px;
  background-color: #f14306;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-send:hover {
  background-color: #f14306;
}

.message-form-cont {
  margin: 1rem 0;
}

.message-form {
  display: flex;
  flex-direction: column;
}

.message-form.disabled {
  opacity: 0.5;
  pointer-events: none;
  filter: grayscale(1);
  cursor: not-allowed;
}

.message-subject,
.message-input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
}

.btn-send {
  padding: 10px 20px;
  background-color: #f14306;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-send:hover {
  background-color: #f15217;
}

.btn-send:focus {
  outline: none;
}

.info-box {
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.75rem;
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.info-box h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.info-box p {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.info-box .button {
  display: inline-block;
  padding: 8px 12px;
  background-color: #3498db;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.info-box .button:hover {
  background-color: #2980b9;
}

/* SEARCH */
.search-form {
  width: 100%;
}

.wrapper {
  position: relative;
  width: 100%;
}

.search-input {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
}

.search-input-field {
  flex: 1;
  border: none;
  outline: none;
  padding: 8px;
  font-size: 14px;
  color: #333;
}

.autocom-box {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  max-height: 200px;
  overflow-y: auto;
  padding: 8px;
}

.autocom-item {
  list-style: none;
  padding: 4px 8px;
  cursor: pointer;
}

.icon {
  margin-left: 8px;
  color: #999;
  font-size: 16px;
}

.order-details {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.order-info {
  margin-top: 10px;
}

.order-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.label {
  font-weight: bold;
}

.value {
  color: #333;
}
