.account_detail {
    background-color: #ffffff;
    line-height: 1.5;
    padding: 10px;
}

.account_detail p {
    color: #8D8D8D;
    font-size: 12px;
}

.list_container {
    /* padding: 10px; */
    background-color: white;
}

.list_container_menu{
    background-color: white;
    padding: 10px 10px;
    text-align: right;
    /* hide on desktop */
    display: none;
}
.list_container_menu button{
    padding: 10px;
    margin: auto;
    border-radius: 5px;
}

@media(max-width:760px){
    .list_container_menu{
        display: block;
    }

    .list_container {
        display: none;
    }
    

    
}

.list_container a {
    display: block;
    width: 100%;
    text-align: left;
    padding: 10px;
    transition: all ease .5s;
    background-color: white;
}

.list_container button:hover {
    background-color: #7692B4;
    color: white;
}

.last_list {
    display: flex;
    align-items: center;
}
.last_list * {
    color: #F14306;
    margin-right: 10px;
    font-size: 12px;
}