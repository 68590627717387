.flex_rows {
    display: flex;
    align-items: baseline;
}

.flex_rows i {
    font-size: 20px;
    margin-right: 10px
}

.flex_rows p {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    margin-right: 10px;
    color: #000000;
}


.answer_section {
    /* padding: 10px; */
    max-width: 550px;
    padding-left: 30px;
    overflow: hidden;
    transition: all ease .5s;
}

.answer_section p {
    padding: 10px;
}

.question {
    font-style: normal;
    font-weight: 400;
    font-size: 19px !important;
    line-height: 28px;
    color: #000000;
    cursor: pointer;
    margin: 7px 0px;
}


.control_icon i {
    font-size: 14px !important;
}