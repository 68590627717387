/* CSS for the SubscriptionPaymentPage component */
.container {
  margin: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
}

.plan-container p{
  font-size: 20px;
  margin: 0 10px;
}

.paystack-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
}
.paystack-button:hover{
  background-color: blue;
  transform: scale(1.1);
}

/* CSS for the custom modal */
.custom-modall {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
}

.modal-content h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.modal-content p {
  font-size: 16px;
  margin-bottom: 20px;
}

.modal-content button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}
