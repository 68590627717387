/* AdminFeedback.module.css */

.orderList {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.orderListBody {
  width: 100%;
  /* max-width: 600px; */
  margin: 0 auto;
}

.order_container {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.orderDetails {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.orderDetails h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.orderDetails p {
  font-size: 14px;
  margin-bottom: 2px;
}

.orderInfo p {
  font-size: 14px;
  margin-bottom: 10px;
}

.orderFooter {
  font-size: 12px;
  color: #888;
}

/* Additional styles for a more minimalistic look */

.order_container {
  transition: box-shadow 0.3s ease;
}

.order_container:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

p.No-feedbacks-found {
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
  color: #888;
}
