.list_container p {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0015em;

    /* ON SURFACE */

    color: #0D0F11;
    line-height: 1.6;
}

.list_container p small {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0015em;
    color: #8D8D8D;
}