.order_table table {
    width: 90%;
    padding: 10px;
    background-color: #fff;
    border-collapse: collapse;
}

@media (max-width: 600px) {
    .order_table table {
        width: 100%;
    }
}

.header_section td {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.001em;
    color: #8D8D8D;
    padding: 10px;
    text-align: left;
}

.lists_ {
    margin-top: 20px;
}

.lists_ td {
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #0D0F11;
    padding: 0px 10px;
    text-align: left;
}