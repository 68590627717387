.select_section select {
  padding: 8px;
  gap: 8px;

  width: 120px;
  height: 32px;

  background: #ffffff;
  border: 1px solid rgba(151, 150, 153, 0.24);
  border-radius: 2px;
}

.detail_text p b {
  font-weight: bolder;
}

.detail_text p span {
  font-weight: lighter;
}

.detail_text input {
  padding: 10px;
  gap: 10px;

  width: 430px;
  height: 56px;

  /* SURFACE */

  background: #ffffff;
  /* SECONDARY */

  border: 1px solid #0a2342;
  border-radius: 5px;
}

@media (max-width: 600px) {
  .detail_text input {
    width: 100%;
  }
}

.media_container {
  position: relative;
  width: 153px;
  height: 153px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.media_container i {
  font-size: 70px;
}

.action_button button {
  padding: 10px;
  color: white;
  padding: 10px 30px;
  gap: 10px;

  /* width: 198px; */
  height: 56px;

  /* PRIMARY */

  background: #f14306;
  /* Light Shadow */

  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.detail_text textarea {
  padding: 10px;
  gap: 10px;
  width: 517px;
  height: 182px;
  background: #ffffff;
  border: 1px solid #0a2342;
  border-radius: 5px;
}

.uploads_container {
  position: relative;
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
  .uploads_container {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

.previewed_images {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: white;
  width: 100%;
  height: 100%;
}

.previewed_images img {
  width: 100% !important;
  height: 100%;
}

.upload_list {
  position: relative;
  width: 150px;
  height: 150px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

@media (max-width: 600px) {
  .upload_list {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
}

.upload_list i {
  font-size: 60px;
}

.select_section select {
  width: 200px;
}
