    .title_div p {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: #0D0F11;
        margin-bottom: 20px;
    }

    .order_header {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .order_header div {
        display: flex;
        align-items: center;
    }

    .order_header div input,
    .order_header div select {
        padding: 7px 10px;
        border: none;
        outline: none;
        margin-left: 10px;
        margin-right: 10px;
        cursor: pointer;
    }

    .search_order_input {
        position: relative;
        display: inline-block;
        margin-top: 20px
    }

    .button_lists {
        margin-bottom: 20px;
    }

    .button_lists button {
        padding: 10px;

    }

    .button_lists button:first-child {
        background-color: black;
        color: white;
    }

    