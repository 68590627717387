.back_button_container button {
    background-color: black;
    padding: 5px 10px;
    gap: 5px;
    width: 101px;
    height: 38px;
    background: #0D0F11;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 20px;
}

.back_button_container button span {
    color: white;
}

.back_button_container button i {
    color: white;
}