.pagination {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
}

.pagination div,
.pagination input {
    margin-left: 10px
}

.pagination p {
    margin-left: 10px;
}

.left_slide {
    width: 30px;
    height: 30px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px rgb(202, 202, 202);
}

.left_slide i {
    margin-top: 2px;
    color: black;
}

.pagination input {
    width: 30px;
    height: 30px;
    background-color: white;
    text-align: center;
    border: none;
    outline: none;
}