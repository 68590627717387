.order_header {
    width: 100%;
    display: flex;
    align-items: center;
}

.order_header div {
    display: flex;
    align-items: center;
}

.order_header div input,
.order_header div select {
    padding: 7px 10px;
    border: none;
    outline: none;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.search_order_input {
    position: relative;
    display: inline-block;
    margin-top: 20px
}

.search_order_input input {
    width: 250px;
    padding: 10px;
    border: 0px;
    outline: none;
    background-color: white;
    height: 40px;
    border-radius: 5px;
}

.search_icon {
    width: 40px;
    height: 40px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    right: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.search_icon i {
    color: white;
}

.pagination {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
}

.pagination div, .pagination input {
    margin-left: 10px
}

.pagination p {
    margin-left: 10px;
} 

.left_slide {
    width: 30px;
    height: 30px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px rgb(202, 202, 202);
}

.left_slide i {
    margin-top: 2px;
    color: black;
}

.pagination input {
    width: 30px;
    height: 30px;
    background-color: white;
    text-align: center;
    border: none;
    outline: none;
}

.title_top {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #0D0F11;
}

.col_two {
    display: flex;
    align-items: center;
}

.col_two i {
    color: #F14306;
    margin-right: 10px;
}


.location_container select {
    background-color: white;
    outline: none;
    border: none;
    padding: 7px;    
    margin: 10px 0px;
}
