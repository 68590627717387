
/* Modal CSS */
.modal-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    overflow: auto;
    z-index: 10000;
    transform: translate(-50%, -50%);
  }
  
.modal {
  max-width: 1000px;
  margin: auto;
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: block;
}

.modal h2 {
  text-align: center;
  margin-bottom: 10px;
}

.modal button {
  margin-top: 10px;
}
#modally{
  font-size: 20px; 
}

/* vod means vendor order details page */
.vod-container{
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 10px rgb(197, 197, 197);
  margin-bottom: 20px;
}
.vod-container p{
  font-size: 16px;
}

.vod-back-btn{
  padding: 10px 20px;
  background-color: black;
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vod-back-btn i{
  color: white;
}

.v-order-item{
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0xp 10px rgb(165, 164, 164);
  border-radius: 10px;
  margin-bottom: 20px;
}
.v-order-item:hover{
  box-shadow: 0px 0xp 10px rgb(97, 96, 96);
  cursor: pointer;
  transition: 0.3s;
}
.v-order-item h2{
  color: orangered;
  margin-bottom: 10px;
}
.v-order-item p{
  font-size: 16px;
  margin-bottom: 10;
}