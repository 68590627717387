.cart_container {
    position: relative;
    display: flex;
    background-color: white;
    margin-top: 20px;
    padding: 10px;
    box-shadow: 3px 12px 10px #d4d4d4;
}


.close_div {
    position: absolute;
    top: 10px;
    right: 10px;
}

.close_div i {
    color: black;
}

.product_image img {
    width: 100px;
    height: 100px;
    margin-right: 20px;
}

.action_cart_area {
    display: flex;
    align-items: center;
}

.descriotion_container {
    margin-bottom: 10px;
}

.action_cart_area input {
    width: 30px;
    height: 30px;
    text-align: center;
    border: none;
    outline: none;
    margin-top: -10px;
    background-color: #ddd;
}

.action_cart_area i {
    font-size: 30px;
    color: black;
}

.price_area h2 {
    color: #F14306;
}


.add_review_button button {
    background-color: unset;
    color: #F14306;
}