/* Styles for the order page */
.container {
    margin-top: 20px;
  }
  
  .card {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .card-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #ccc;
  }
  
  .card-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .card-body {
    padding: 20px;
  }
  
  h3 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  /* Add some margin to the "Confirm Order" button */
  .btn {
    margin-top: 10px;
  }
  /* CSS to mimic Bootstrap's .mt-4 class */
.mt-4 {
    margin-top: 1.5rem;
  }

  /* myorder-style.css */
/* Glassmorphism styles */
.order-details {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(30px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
  }
  
  /* Style the "Confirm Order" button */
  .btn-confirm {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-confirm:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
  
  .order-list{
    background-color: white;
  }