.review_container {
    display: flex;
    margin-bottom: 20px;
}
.review_container img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.details_section p {
    /* line-height: 2.4; */
    margin: 10px 0px;
}

.details_section {
    max-width: 300px;
}