.title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;

    /* ON BACKGROUND */

    color: #0D0F11;

}

.order_header {
    width: 100%;
    display: flex;
    align-items: center;
}

.order_header div {
    display: flex;
    align-items: center;
}

.order_header div input,
.order_header div select {
    padding: 7px 10px;
    border: none;
    outline: none;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.search_order_input {
    position: relative;
    display: inline-block;
    margin-top: 20px
}

.button_lists {
    margin-bottom: 20px;
}

.button_lists button {
    padding: 10px;

}

.button_lists button:first-child {
    background-color: black;
    color: white;
}

.nav_container button {
    padding: 10px;
    margin-right: 10px;
    color: #7692B4;
    background-color: unset;
    transition: all ease .5s;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

/* .nav_container button:first-child {
    background-color: #0A2342;
    color: white;
}  */