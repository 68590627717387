.product_list_container {
    padding: 6px;
    flex-basis: 20%;
    flex-wrap: wrap;
}

.product_list_item {
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height: 300px;
}

@media (max-width: 600px) {
    .product_list_container {
        flex-basis: 50%;
    }
}


.product_list_container img {
    width: 100%;
    height: 150px;
    object-fit: fill;
    display: block;
    margin: auto;
}


.amount_container {
    bottom: 0px;
    padding-bottom: 10px;
    width: 90%;
}

.amount_container h2 {
    color: #F14306;
}