.nav_container button {
    padding: 10px;
    margin-right: 10px;
    color: #7692B4;
}

.nav_container button:first-child {
    /* background-color: #0A2342; */
    /* color: white; */
}



.order_header {
    width: 100%;
    display: flex;
    align-items: center;
}

.order_header div {
    display: flex;
    align-items: center;
}

.order_header div input,
.order_header div select {
    padding: 7px 10px;
    border: none;
    outline: none;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.search_order_input {
    position: relative;
    display: inline-block;
    margin-top: 20px
}

.button_lists {
    margin-bottom: 20px;
}

.button_lists button {
    padding: 10px;

}

.button_lists button:first-child {
    background-color: black;
    color: white;
}

.nav_container button {
    padding: 10px;
    margin-right: 10px;
    color: #7692B4;
    background-color: unset;
}

.nav_container button:first-child {
    background-color: #0A2342;
    color: white;
}