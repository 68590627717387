.order_container {
  padding: 10px;
  background-color: white;
  line-height: 1.8;
  margin-bottom: 10px;
}

.order_container p {
  width: 50%;
  color: #8d8d8d;
}

.cart_sumary_div_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cart_sumary_div_list h3 {
  color: #f14306;
}

.footer_section_for_order div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.footer_section_for_order div p {
  margin-right: 10px;
}

.footer_section_for_order div h3 {
  color: #f14306;
}

.review_section p {
  color: #7692b4;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.0015em;
  color: #7692b4;
}

/* Add address section */
.add_adderess_button button {
  display: block;
  margin: auto;
  background-color: #f14306;
  padding: 13px 30px;
  color: white;
  font-weight: bold;
}

.wallet_add_utton {
  display: inline;
}

.addresses_container {
  padding: 10px;
  /* display: flex; */
  align-items: center;
  background-color: white;
  margin-bottom: 10px;
  line-height: 1.7;
}

.head_sextion {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.head_sextion button {
  padding: 10px 20px;
}

.btn_2 {
  width: 50%;
}

.addresses_container input {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.addresses_container h3 {
  margin-bottom: 10px;
}

.application_form {
  margin-bottom: 15px;
}

.application_form input,
.application_form textarea,
.application_form select {
  width: 50%;
  display: block;
  padding: 10px;
}

@media (max-width: 600px) {
  .application_form input,
  .application_form textarea,
  .application_form select {
    width: 100%;
  }
}

.application_form textarea {
  height: 150px;
}

.application_form label {
  font-size: 12px;
}

.wallet_amount h3 {
  color: #f14306;
}

.form_centered_section {
  width: 400px;
}
@media (max-width: 600px) {
  .form_centered_section {
    width: 100%;
  }
}
.form_centered_section input,
.form_centered_section select {
  width: 100%;
}

.form_centered_section .submit_button button {
  display: block;
  width: 100%;
  background-color: #f14306;
  padding: 20px;
  color: white;
}

.button_sections button {
  padding: 20px 40px;
  background-color: #f14306;
  margin-right: 10px;
}

.vendor_form {
  display: block;
  margin: auto;
  max-width: 500px;
  /* padding: 10px; */
}

@media (max-width: 600px) {
  .vendor_form {
    width: 100%;
  }
}
.vendor_form h1 {
  text-align: center;
}

.pagination {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
  align-items: center;
  padding: 1rem;
}

.btn {
  padding: 10px;
  color: white;
  background-color: #f14306;
  width: fit-content;
  margin: 0.25rem;
  border-radius: 5px;
  margin-bottom: 12px;
}

.btn:disabled {
  filter: grayscale(1);
  cursor: not-allowed;
}
