.main {
  display: flex;
  background-color: #e7e7e7;
  min-height: 100vh;
}

.aside {
  flex-basis: 20%;
  background-color: #ffffff;
  padding: 20px 38px;
}
.article {
  flex-basis: 80%;
  background-color: #e7e7e7;
  padding: 20px;
}

@media (max-width: 600px) {
  .article {
    width: 100%;
    flex-basis: 100%;
  }
  .aside {
    display: none;
  }
}
