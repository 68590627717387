.title_section {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #0D0F11;
    margin-top: 10px;
}

.form_list_div {
    margin-top: 1rem;
}



.form_list_div b {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #0D0F11;
}


.form_list_div small {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #0D0F11;
}

.form_list_div input[type=text],
.form_list_div input[type=number],
.form_list_div textarea,
.form_list_div select {
    width: 430px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #0A2342;
    border-radius: 5px;
    margin-top: 4px;
    outline: none;
    padding: 0px 10px;
}

.form_list_div textarea {
    width: 626px;
    height: 183px;
    background: #FFFFFF;
    border: 1px solid #0A2342;
    border-radius: 5px;
    padding: 10px;
}

@media (max-width: 600px) {

    .form_list_div input,
    .form_list_div textarea,
    .form_list_div select {
        width: 100%;
    }
}

.flexed_container {
    line-height: 3.5;
}

.flexed_container p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #8D8D8D;
    margin-top: 5px;
    line-height: 1.5;
}

.flexed_container {
    display: flex;
    align-items: center;
}

@media (max-width: 600px) {
    .flexed_container {
        flex-direction: column;
        /* background-color: pink; */
    }
}

.input_list_container {
    flex-basis: 40%;
    margin-right: 20px;

}

@media (max-width: 600px) {
    .input_list_container {
        width: 100%;
        flex-basis: 100%;
        margin: unset
    }
}

.input_list_container ::placeholder {
    color: black;
    font-weight: bold;
}

.input_list_container select,
.input_list_container input {
    width: 100%;
    width: 430px;
    height: 56px;
    background: #FFFFFF;
    border: 1px solid #0A2342;
    border-radius: 5px;
    padding: 0px 10px;
    outline: 0;
}

@media (max-width: 600px) {

    .input_list_container select,
    .input_list_container input {
        width: 100%;
    }
}


.specification_title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: black;
}

.specifications {
    display: flex;
    align-items: center;
}

.specifications button {
    padding: 10px 20px;
    width: 206px;
    height: 56px;
    color: white;
    margin: 16px 0px;
    background: #0A2342;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    margin-right: 20px;

}

@media (max-width: 600px) {
    .specifications button {
        display: block;
        margin: auto;
        margin-top: 10px;
    }
}


.uploads_container {
    position: relative;
    display: flex;
    align-items: center;
}

@media (max-width: 600px) {
    .uploads_container {
        flex-wrap: wrap;
        justify-content: space-around;
    }
}


.previewed_images {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: white;
    width: 100%;
    height: 100%;
}

.previewed_images img {
    width: 100% !important;
    height: 100%;
}



.upload_list {
    position: relative;
    width: 150px;
    height: 150px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

@media (max-width: 600px) {
    .upload_list {
        width: 100px;
        height: 100px;
        margin-bottom: 10px;
    }
}

.upload_list i {
    font-size: 60px;
}

.brand_text {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #7692B4 !important;
    margin-top: 10px;
}

.brand_text_ {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0015em;

    /* ON BACKGROUND */

    color: #0D0F11;
}

.edit_pricing {
    display: flex;
}

@media (max-width: 600px) {
    .edit_pricing {
        flex-direction: column;
    }
}

.edit_pricing .flex_basis {
    flex-basis: 40%;
    margin-right: 20px;
}

@media (max-width: 600px) {
    .edit_pricing .flex_basis {
        margin: unset;
        margin-bottom: 10px;
    }
}

.edit_pricing div input[type=text],
.edit_pricing div input[type=number] {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
}

.footer_button {
    display: flex;
    align-items: center;
}

.footer_button button,
.disgard_changes {
    display: inline;
    cursor: pointer;
    padding: 20px 24px;
    background-color: #F14306;
    color: white;
    margin-right: 10px;
}

.flex_promoted_price input {
    width: 20px;
    display: inline-block;
    margin-right: 10px;

}


.flex_promoted_price {
    display: flex;
    /* align-items: center; */
}

.color_input label {
    margin-right: 10px;
}

.color_input input {
    margin-right: 5px;
}

.showMoreHideMore {
    padding: 14px;
    color: white;
    margin-top: 10px;
    display: inline-block;
    cursor: pointer;

}

.spinner_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
