.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: black;
    padding: 10px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
}

.header p {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #FFFFFF;
}

.header img {
    width: 200px;
}

.flex_fist_div {
    display: flex;
    align-items: center;
}

.flex_fist_div button {
    padding: 10px;
    margin: 0px 10px;
    border-radius: 10px;
    background-color: white;
    color: #F14306;
    font-weight: bolder;
}