.search_order_input {
    position: relative;
    display: inline-block;
    margin-top: 20px
}

.search_order_input input {
    width: 250px;
    padding: 10px;
    border: 0px;
    outline: none;
    background-color: white;
    height: 40px;
    border-radius: 5px;
}

.search_icon {
    width: 40px;
    height: 40px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    right: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.search_icon i {
    color: white;
}
