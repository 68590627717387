.main {
    display: flex;
    /* height: 100vh; */
}

.aside {
    margin-left: 10px;
    flex-basis: 20%;
   
}

.article {
    flex-basis: 80%;
    padding: 10px;
}

@media (max-width: 900px) {
    main {
        flex-direction: column;
    }
    .aside {
        display: block;
        width: 90%;
        margin: auto;

    }

    .article {
        width: 100%;
        flex-basis: 100%;
        padding: unset;
    }
}