.main {
    display: flex;
    /* height: 100vh; */
}

.aside {
    flex-basis: 20%;
}

.article {
    flex-basis: 80%;
    padding: 10px;
}

@media (max-width: 600px) {
    .aside {
        display: none;
    }

    .article {
        width: 100%;
        flex-basis: 100%;
        /* padding: unset; */
    }
}