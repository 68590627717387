.main {
    display: flex;
    /* height: 100vh; */
}

.aside {
    flex-basis: 20%;
    padding: 10px;
}
.article {
    flex-basis: 80%;
    padding: 10px;
}