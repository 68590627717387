.article {
  padding: 30px;
  max-width: 88%;
  display: block;
  margin: auto;
  background-color: white;
}

@media (max-width: 600px) {
  .article {
    padding: 0;
    max-width: 100%;
    padding: 10px;
  }
}

.first_details_section {
  display: flex;
}

.large_image_preview {
  flex-basis: 50%;
  background-color: white;
  border-radius: 20px;
}

.right_detail_preview {
  flex-basis: 50%;
  /* padding: 10px; */
  line-height: 2;
  /* margin-left: 30px; */
  padding-top: 0;
  margin-left: 25px;
}

@media (max-width: 600px) {
  .right_detail_preview {
    margin-left: unset;
  }
}

.right_detail_preview h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 40px;
  letter-spacing: 0.0025em;
  color: #0d0f11;
}

@media (max-width: 600px) {
  .first_details_section {
    flex-direction: column;
  }
}

.large_image_preview img {
  width: 100%;
  max-height: 400px;
  border-radius: 20px;
}

.rating {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 1rem;
}

.rating i {
  color: #ffb800;
}

.three_small_images_prev img {
  width: 120px;
  height: 100px;
  margin: 20px 0px;
  margin-right: 10px;
  border-radius: 10px;
}

.action_button button {
  width: 100%;
  padding: 10px;
  background-color: #f14306;
  color: white;
  font-size: 20px;
  border-radius: 7px;
}

.action_button button i {
  margin-right: 10px;
  color: white;
}

.detail_section_div {
  display: flex;
}

@media (max-width: 600px) {
  .detail_section_div {
    flex-direction: column;
  }
}

.descritpion_section {
  flex-basis: 50%;
  margin-bottom: 20px;
}

.revires_container {
  flex-basis: 50%;
}

.specifications table,
.specifications tr,
.specifications td {
  padding: 20px 50px;
  border: 1px solid black;
  border-collapse: collapse;
}

.specifications h3 {
  margin-bottom: 10px;
}

.brand_name {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
  color: #7692b4 !important;
}

.powered_by p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.0015em;
  color: #7692b4;
}

.powered_by h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #0d0f11;
}

.vendor_buttons button {
  padding: 20px 30px;
  color: white;
  background-color: #f14306;
  margin-right: 10px;
}
