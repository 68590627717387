.container_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.container_section h3 {
    text-align: right;
}

.product_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}