
/* Form styles */
.form-container {
margin: 20px;
padding: 20px;
border: 1px solid #ccc;
border-radius: 5px;
}

/* Input styles */
.form-control {
margin-bottom: 10px;
}

/* Button styles */
.btn {
margin-right: 10px;
}

/* Table styles */
.table {
margin-top: 60px;
}

/* Error message styles */
.error {
color: red;
}

/* modal-styles.css */

/* Customize the modal size */
.custom-modal {
max-width: 500px; /* Adjust the width as needed */
}

/* Customize the modal background color */
.custom-modal-background {
background-color: rgba(0, 0, 0, 1); /* Semi-transparent black background */
}


#form11 {
display: flex;
flex-direction: column;
max-width: 500px; /* Adjust the maximum width as needed */
margin: 0 auto;
}

/* Styling for Form Inputs */
#form11 input[type="text"],
#form11 input[type="number"] {
padding: 10px;
margin-bottom: 10px;
border: 1px solid #ccc;
border-radius: 5px;
font-size: 16px;
display: block;
width: 100%;
}

#form11.btn{
    margin-bottom: 10px;
}

.h2-center{
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
}

.btn-right{
    float: right;
}