.admin_cart_list {
    display: flex;
    background-color: white;
    padding: 10px;
    border-radius: 7px;
    box-shadow: 0px 0px 10px rgb(213, 213, 213);
    margin-bottom: 10px;
}

.image_div img {
    width: 106px;
    height: 89px;
    margin-right: 10px;
}

@media (max-width: 600px) {
    .image_div img {
        width: 70px;
    }
}

.footer_section {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 10px;
}

@media (max-width: 600px) {
    .footer_section {
        flex-wrap: wrap;
    }
}

.details_section {
    width: 100%;
}

.details_section p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #0D0F11;
}

.details_section h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #0D0F11;
    margin-top: 1rem;
}

.footer_section input {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005em;
    color: #8D8D8D;
    border: 1px solid gray;
    height: 25px;
    padding: 0px 10px;
    outline: none;
}

@media (max-width: 600px) {
    .footer_section input {
        width: 150px;
    }
}

.footer_section button {
    /* height: 25px; */
    padding: 7px 20px;
    background-color: #F14306;
    border-radius: 7px;
    color: white;
}




.footer_section h2 {
    color: #F14306;
}