.top_header_div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.top_header_div button {
    padding: 20px;
    color: black;
    font-weight: bold;
}