.container {
    background-color: white;
    padding: 10px;
    box-shadow: 0px 0px 10px rgb(213, 213, 213);
}

.container h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #0D0F11;
    margin-bottom: 10px;

}

.container p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.005em;
    color: #000000;
}

.flex {
    display: flex;
    align-items: center;
}

.flex img {
    margin-left: 20px;
}