.ttitle {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #0D0F11;
}

.filter_section {
    display: flex;
}

.filter_section select {
    padding: 10px;
    margin-right: 10px;
}


.search_order_input {
    position: relative;
    display: inline-block;
    margin-top: 20px
}

.search_order_input input {
    width: 250px;
    padding: 10px;
    border: 0px;
    outline: none;
    background-color: white;
    height: 40px;
    border-radius: 5px;
}

.search_icon {
    width: 40px;
    height: 40px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    right: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.search_icon i {
    color: white;
}

.results_container {
    margin-top: 20px;
}
.results_container h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #0D0F11;
    margin-bottom: 3px;
}



.pagination {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
}

.pagination div, .pagination input {
    margin-left: 10px
}

.pagination p {
    margin-left: 10px;
} 

.left_slide {
    width: 30px;
    height: 30px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px rgb(202, 202, 202);
}

.left_slide i {
    margin-top: 2px;
    color: black;
}

.pagination input {
    width: 30px;
    height: 30px;
    background-color: white;
    text-align: center;
    border: none;
    outline: none;
}

.order_table table {
    width: 100%;
    padding: 10px;
    background-color: #fff;
    border-collapse: collapse;
}

@media (max-width: 600px) {
    .order_table table { 
        width: 100%;
    }
}

.header_section td {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.001em;
    color: #8D8D8D;
    padding: 10px;
    text-align: left;
}
.lists_ {
    margin-top: 20px;
}
.lists_ td {
    font-style: normal;
    font-weight: 400;
    /* font-size: 20px; */
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #0D0F11;
    padding: 0px 10px ;
    text-align: left;
}

