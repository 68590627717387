.footer {
    width: 100%;
    background-color: #0D0F11;
    padding: 10px;
    color: white;
    margin-top: 20px;
}

.col1 img {
    width: 250px;
}

.footer {
    display: flex;
    justify-content: space-between;
    padding: 30px 10px;
}



.col1,
.col2,
.col3 {
    flex-basis: 30%;
    line-height: 1.6;
}

footer i {
    margin-right: 10px;
    color: white;
}

.footer h2 {
    margin-bottom: 12px;
}

@media (max-width: 600px) {
    .footer {
        flex-direction: column;
    }

    .col1,
    .col2,
    .col3 {
        margin-bottom: 10px;
    }
}

.cols{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.cols div{
    flex: 1;
}

footer>div{
    flex: 1;
}

.col1{
    max-width: 400px;
}

@media (min-width: 900px) {
    .col1{
        margin-right: 20%;
    }

    .cols{
        margin-top: 60px;
    }
}
