.product_container p {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #0D0F11;
}

.product_container h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #7692B4;
}

.product_images img {
    width: 200px;
    height: 200px;
    margin-right: 10px;
    margin-top: 10px;
}

@media (max-width: 600px) {
    .product_images img {
        width: 100%;
    }
}

.performance p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #0D0F11;
    margin-bottom: 10px;
}

@media (max-width: 600px) {
    .performance img {
        width: 100%;
    }
}

.price_property,
.descriptio_property,
.color_property {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #8D8D8D;
    margin-bottom: 10px;
}

.price_property b,
.descriptio_property b,
.color_property b {
    font-weight: bolder;
    color: black;
}

.descriptio_property b {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: black;

}

.tabler_section table {
    border-collapse: collapse;
}

.tabler_section table td {
    padding: 10px 20px;
    border: 1px solid black;

}

.tabler_section p {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #8D8D8D;
    margin-bottom: 10px;
}


.edit_button button {
    padding: 10px;
    gap: 10px;
    width: 175px;
    height: 56px;
    background: #F14306;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    color: white;
    margin-top: 14px;
}



.posted_by_name p:first-child {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #0D0F11;
}

.posted_by_name p:last-child {
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #0D0F11;
}

.action_button button{
    width: 150px;
    margin-right: 20px;
}

.action_button button:last-child {
    background-color: #FF0000;
}