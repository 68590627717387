.report_wrapper p:first-child {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #0D0F11;
}

.report_wrapper p:nth-child(2) {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #7692B4;
}

.report_wrapper p:nth-child(3) {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    color: #0D0F11;
}

.report_body .body_text {
    background-color: #cecece;
    padding: 10px;
    width: 552px;
    margin: 10px 0px;
    height: auto;
}


@media (max-width: 600px) {
    .report_body .body_text {
        width: 100%;
    }
}

.body_text p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0015em;

    /* ON BACKGROUND */

    color: #0D0F11;
}

.report_body textarea {
    padding: 10px;
    gap: 10px;
    width: 552px;
    height: 176px;
    background: #FFFFFF;
    border: 1px solid #0A2342;
    border-radius: 5px;
}

@media (max-width: 600px) {
    .report_body textarea {
        width: 100%;
    }
}

.uplaod_div i,
.trash_icon {
    font-size: 27px;
    margin-top: 10px;
}

.action_button {
    display: flex;
}

.action_button button {
    padding: 10px 20px;
    gap: 10px;

    height: 56px;

    /* PRIMARY */

    background: #F14306;
    /* Light Shadow */
    color: white;
    margin-right: 10px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
}

.action_button button:last-child {
    background-color: red;
}

.input_section input {
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    width: 552px;
    height: 56px;

    /* SURFACE */

    background: #FFFFFF;
    /* SECONDARY */

    border: 1px solid #0A2342;
    border-radius: 5px;

}
@media (max-width: 600px) {
    .input_section input {
        width: 100%;
    }
}